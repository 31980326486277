export const DEFAULT_TIMEZONE = 'JST';
export const DEFAULT_DATE_PATTERN = 'DD/MM/YYYY';
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,15}$/;
export const PASSWORD_VALIDATION_ERROR_MESSAGE =
  'Password should be between 8 to 15 characters and must include at least one uppercase letter, one lowercase letter, one numeric digit, and one special character (@#$%^&+=!)';

export const TIME_ZONE = [
  {
    key: 'Japan (GMT +9) Tokyo',
    value: 'JST',
  },
];

export const DATE_FORMATS = [
  {
    key: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
    example: '31/08/2023',
  },
  {
    key: 'YYYY-MM-DD',
    value: 'YYYY-MM-DD',
    example: '2023-08-31',
  },
  {
    key: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
    example: '08/31/2023',
  },
  {
    key: 'DD MMMM YYYY',
    value: 'DD MMMM YYYY',
    example: '31 August 2023',
  },
  {
    key: 'MM/DD/YY',
    value: 'MM/DD/YY',
    example: '08/31/23',
  },
  {
    key: 'D MMM YYYY',
    value: 'D MMM YYYY',
    example: '31 Aug 2023',
  },
  {
    key: 'YYYY/MM/DD',
    value: 'YYYY/MM/DD',
    example: '2023/08/31',
  },
];

export const LEAVE_QUESTIONS = [
  {
    id: 1,
    value: 'Going abroad or working holiday',
  },
  {
    id: 2,
    value: 'The application is difficult to use and the service is poor.',
  },
  {
    id: 3,
    value: 'There are few companies listed in the application',
  },
  
];

export const DELETE_QUESTIONS = [
  {
    id: 1,
    value: 'Going to graduate school or other schools',
  },
  {
    id: 2,
    value: 'Going abroad or working holiday',
  },
  {
    id: 3,
    value: 'The application is difficult to use and the service is poor.',
  },
  {
    id: 4,
    value: ' There are few companies listed in the application',
  },
];