export const profileImageSize = (size = 'medium') => {
  switch (size) {
    case 'small':
      return '1rem';
    case 'medium':
      return '2rem';
    case 'large':
      return '4rem';
    default:
      return '2rem';
  }
};

export const convertMsImageToImageSrc = imageData => {
  try {
    const url = window.URL || window.webkitURL;
    const blobUrl = url.createObjectURL(imageData);
    return blobUrl;
  } catch (error) {
    console.error('Error converting image:', error);
    return null;
  }
};

export const generateInitialsAvatar = name => {
  const canvas = document.createElement('canvas');
  canvas.width = 100; // Adjust width and height as needed for your avatar size
  canvas.height = 100;

  const ctx = canvas.getContext('2d');

  // Draw background
  ctx.fillStyle = '#f0f0f0'; // Background color
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Draw initials
  ctx.font = '48px Arial'; // Adjust font size and style as needed
  ctx.fillStyle = '#000000'; // Initials color
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  const isFullName = String(name).split(' ').length >= 2;
  const initials = !name
    ? '--'
    : isFullName
    ? name
        .split(' ')
        .slice(0, 2) // Take only the first two words
        .map(word => word[0]?.toUpperCase())
        .join('')
    : name.toUpperCase().substr(0, 2);

  ctx.fillText(initials, canvas.width / 2, canvas.height / 2);

  const dataURL = canvas.toDataURL();
  return dataURL;
};
