//Import Payment realted icons
import paymentMasterIcon from 'assets/images/master.png';
import paymentVisaIcon from 'assets/images/visa.png';
import paymentStripeIcon from 'assets/images/stripe.png';
import paymentPaypalIcon from 'assets/images/paypal.png';

export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/images/univ-logo.png';
export const BANNER = '/banner.jpeg';
export const ONBOARDING_ICON = '/onboardingIcon.png';
export const ONBOARDING_ICON_2 = '/get-started.jpg';
export const PROFILE_IMAGE = '/profile-avatar.png';
export const ONBOARDING_PROGILE_BG = '/profile-bg.png';
export const COMPANY_TOP_BANNER = '/dashboard-bg.png';

export const COMPANY_LOGO = '/icon-1.png';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const PROFILE_BANNER_IMAGE = '/images/profile-banner.png';
export const EMPTY_DASHBOARD = '/empty-dashboard.jpeg';
export const UPLOAD_ICON = '/upload-icon.png';
export const COMPANY_DETAILS_BG = '/company-details-bg.jpg';
export const UPLOAD_IMAGE = '/upload-icon.svg';

export const RELATIONSHIP_MENU_ICON = '/images/relationship-menu-icon.png';
export const DEFAULT_ADDITINAL_INFO_BANNER =
  '/images/additional-info-cmp-banner.jpg';
export const SUBSCRIPTION_INOVICE_PAYMENT_PDF_PATH = '/dummy.pdf';

//Export Payment Related Icons
export const PAYMENT_STRIPE_ICON = paymentStripeIcon;
export const PAYMENT_VISA_ICON = paymentVisaIcon;
export const PAYMENT_MASTER_ICON = paymentMasterIcon;
export const PAYMENT_PAYPAL_ICON = paymentPaypalIcon;
export const EMPTY_BAR_GRAPH = '/empty-bar-graph.png';
export const EMPTY_PIE_CHART = '/empty-pie-chart.png';

//Export Empty Icons
export const EMPTY_IMAGE = '/empty-img.png';
export const SEMINAR_EMPTY_ICON = '/images/seminar-empty-icon.png';
export const RELATIUONSHIP_EMPTY_ICON = '/images/relationship-empty-icon.png';
export const COMPANY_EMPTY_ICON = '/images/company-empty-icon.png';
export const AGREEMENT_EMPTY_ICON = '/images/agreement-empty-icon.png';
export const JOBS_RESULT_EMPTY_ICON = '/images/jobs-result.jpg';
export const DEFAULT_UNIV_LOGO='/company-logo.png'

//Profle Related Images
export const DEFAULT_CAREER_OFFICE_TOPIC_BANNER =
  '/images/career-office-topic-banner.jpg';

//Help Tab Icons
export const HELP_CREATE_ACCOUNT_ICON = '/images/account.png';
export const HELP_UNIVERSITY_INFO_ICON = '/images/university.png';
export const HELP_LOGIN_ICON = '/images/login.png';
export const HELP_HANDSHAKE_ICON = '/images/handshake.png';
export const HELP_JOBS_ICON = '/images/jobs.png';
export const HELP_INTERNSHIP_ICON = '/images/internship.png';
export const HELP_SEMNAR_ICON = '/images/seminar.png';
export const HELP_OTHERS_ICON = '/images/others.png';
export const HELP_SHCOLARSHIP_AND_DONATION_ICON = '/images/scholarship.png';
export const HELP_MANAGE_USER_ICON = '/images/user.png';
