import { formatTimeRange, formattoEnglishDate } from 'utils/helpers/date';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const JOB_MODE_ONLINE = 'Online';
export const JOB_MODE_OFFLINE = 'Offline';
export const JOB_MODES = [
  {
    name: JOB_MODE_ONLINE,
    value: JOB_MODE_ONLINE,
  },
  {
    name: JOB_MODE_OFFLINE,
    value: JOB_MODE_OFFLINE,
  },
];

export const STATUS = {
  APPLIED: 'APPLIED',
  ACCEPTED: 'ACCEPTED',
  DENIED: 'DENIED',
  DENY: 'DENY',
  DELETED: 'DELETED',
  REJECTED: 'REJECTED',
  INITIATED: 'INITIATED',
  SELECTED: 'SELECTED',
  RESCHEDULE: 'RESCHEDULE',
  REGISTERED: 'REGISTERED',
  ATTENDED: 'ATTENDED',
  NEW: 'NEW',
  HOLD: 'HOLD',
  EMPTY: '',
  NULL: null,
  CONFIRMED: 'CONFIRMED',
  REQUESTED: 'REQUESTED',
  RECOMMENDATION_INITIATED: 'RECOMMENDATION_INITIATED',
  RECOMMENDATION_ACCEPTED: 'RECOMMENDATION_ACCEPTED',
  RECOMMENDATION_DENIED: 'RECOMMENDATION_DENIED',
  RECOMMENDATION_ATTENDED: 'RECOMMENDATION_ATTENDED',
  REESTABLISH_RELATIONSHIP: 'REESTABLISH_RELATIONSHIP',
  VERIFIED: 'VERIFIED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ENTRY_DEADLINE: 'entryDeadline',
};

export const JOB_CATEGORY = {
  PUBLIC: 'PUBLIC',
  UNIVERSITY: 'UNIVERSITY',
  TARGETED_JOB_POSTING: 'TARGETED_JOB_POSTING',
  OPEN_TO_ALL: 'OPEN_TO_ALL',
};

export const YES = 'Yes';
export const NO = 'No';
export const ACCEPTED = 'Accepted';
export const NOT_APPLIED = 'Not Applied';
export const REJECTED = 'Rejected';
export const RESCHEDULE = 'Reschedule';
export const NEW = 'New';
export const DENIED = 'Denied';
export const INVITED = 'Invited';

export const JOB_STATUS_INTERVIEW = 'SCHEDULED';

export const JOB_OFFER_STATUS_ACCEPTED = 'Accepted';
export const JOB_OFFER_STATUS_REJECTED = 'Rejected';
export const JOB_OFFER_STATUS_DENIED = 'Denied';

export const INTERVIEW_STATUS_ACCEPTED = 'ACCEPTED';
export const INTERVIEW_STATUS_REJECTED = 'REJECTED';
export const INTERVIEW_STATUS_RESCHEDULED = 'RESCHEDULED';
export const INTERVIEW_STATUS_SCHEDULED = 'SCHEDULED';

export const getInterviewSchedule = (startTime, endTime) => {
  return `${formattoEnglishDate(startTime)} - ${formatTimeRange(
    startTime,
    endTime
  )}`;
};

export const JOB_ATTENDED_INTERVIEWS = 'attendedInterviews';
export const JOB_OFFERS = 'jobOffers';
export const JOB_FAILED_INTERVIEWS = 'failedInterviews';

export const JOB_ANALYSIS_MAP = {
  [JOB_OFFERS]: 'Job Offers',
  [JOB_FAILED_INTERVIEWS]: 'Interviews Failed',
  [JOB_ATTENDED_INTERVIEWS]: 'Interviews Attended',
};
