import { theme } from 'utils/theme';

export const SEMINAR_STATUS_ATTENDED = 'ATTENDED';
export const SEMINAR_STATUS_REGISTERED = 'REGISTERED';
export const SEMINAR_STATUS_ACTIVE = 'ACTIVE';
export const TIME_FORMAT_CHARACTERS_ALLOWED = /[^0-9:aAmMpPmMtToOT\s-]/g;

export const SEMINAR_STYLE = {
  REMOTE: 'REMOTE',
  ONSITE: 'ONSITE',
};

export const SEMINAR_TYPE_ONLINE = 'ONLINE';
export const SEMINAR_TYPE_OFFLINE = 'OFFLINE';

export const SEMINAR_CATEGOREIES = {
  PUBLIC: 'PUBLIC',
  UNIVERSITY: 'UNIVERSITY',
};

export const initialSeminarStyleOptions = {
  label: 'Seminar Style',
  value: 'type',
  options: [
    {
      value: 'REMOTE',
      label: 'REMOTE',
      checked: false,
    },
    {
      value: 'ONSITE',
      label: 'ONSITE',
      checked: false,
    },
  ],
};

export const SEMINAR_FILTER_OPTIONS = {
  SEMINAR_ID: 'seminarId',
  COMPANY_ID: 'companyId',
  COMPANY: 'company',
  FACULTY: 'faculty',
  LOCATION: 'location',
  TITLE: 'seminarTitle',
  STYLE: 'seminarStyle',
  INDUSTRY: 'industry',
  REQUESTED_SEMINARS: 'requestedSeminars',
  ACCEPTED_SEMINARS: 'acceptedSeminars',
  ATTENDED_SEMINARS: 'attendedSeminars',
  START_DATE: 'seminarStartDate',
  REQUESTED_DATE: 'requestedDate',
  RESPOND_BY: 'respondBy',
  DENIED_DATE: 'deniedDate',
  ACCEPTED_DATE: 'acceptedDate',
  ATTENDED_DATE: 'attendedDate',
  INTERNSHIP_TITLE:"internshipTitle"
};

export const SEMINAR_BOOKING_STATUS = {
  BOOKING_REQUEST: 'BOOKING_REQUEST',
  SEND_BOOKING: 'SEND_BOOKING',
  ROOM_BOOKED: 'ROOM_BOOKED',
  ROOM_CONFIRMED: 'ROOM_CONFIRMED',
};

export const bookingStatusMappings = {
  [SEMINAR_BOOKING_STATUS.ROOM_CONFIRMED]: {
    buttonText: 'Room Booked',
    backgroundColor: '#BFBFBF',
  },
  [SEMINAR_BOOKING_STATUS.BOOKING_REQUEST]: {
    buttonText: 'Booking Request',
    backgroundColor: '#FF8600',
  },
  [SEMINAR_BOOKING_STATUS.ROOM_BOOKED]: {
    buttonText: 'Request Sent',
    backgroundColor: '#17AF38',
  },
};

export const defaultStatusMapping = {
  buttonText: 'Send Booking',
  backgroundColor: '#8478C6',
};
