import { STATUS } from './jobs';
import { SEMINAR_CATEGOREIES } from './seminar';

export const baseEndpoint = (api = '') =>
  process.env.REACT_APP_API_ENDPOINT + api;
export const commonUtilsEndpoint = (api = '') =>
  process.env.REACT_APP_COMMON_API_ENDPOINT + 'common-utilities' + api;
export const swaggerBaseEndpoint = (api = '') =>
  process.env.REACT_APP_API_ENDPOINT + api;
export const universityBaseEndpoint = (api = '') =>
  baseEndpoint() + 'university/v1' + api;
export const swaggerUniversityBaseEndpoint = (api = '') =>
  swaggerBaseEndpoint() + 'university/v1' + api;
export const universityFilterBaseEndpoint = (api = '') =>
  baseEndpoint() + 'university/filter/v1' + api;
export const universityAdminBaseEndpoint = (api = '') =>
  baseEndpoint() + 'university-admin/v1' + api;

// Dashboard Endpoints
export const jobHuntingStatusApi = (params = '') =>
  universityBaseEndpoint(`/dashboard/completed-job-hunt`) + params;
export const selectionAnalysisApi = (user, params = '') =>
  universityBaseEndpoint(`/seminar-dashboard/${user}`) + params;
export const latestJobsApi = () => universityBaseEndpoint('/job/all');
export const latestSeminarsApi = () => universityBaseEndpoint('/seminar/all');

// My Students Endpoints
export const getJobsApi = (params = '') =>
  universityBaseEndpoint('/job/job-hunting/all') + params;
export const jobHuntingStudentNotesApi = (params = '') =>
  universityBaseEndpoint('/job/job-hunting/student-notes' + params);
export const getInternshipsApi = (params = '') =>
  universityBaseEndpoint('/internship/internship-students/all') + params;
export const internshipStudentNotesApi = (params = '') =>
  universityBaseEndpoint(
    '/internship/internship-students/student-notes' + params
  );
export const getMyStudentJobsFacultiesApi = (params = '') =>
  universityBaseEndpoint('/faculty-department-list') + params;
export const getMyStudentInternshipFacultiesApi = (params = '') =>
  universityBaseEndpoint('/faculty-department-list') + params;
export const getMyStudentJobsLocationsApi = (params = '') =>
  universityBaseEndpoint('/faculty-department-list') + params;
export const getMyStudentInternshipLocationsApi = (params = '') =>
  universityBaseEndpoint('/faculty-department-list') + params;
export const getMyStudentDepartmentsApi = (params = '') =>
  universityBaseEndpoint('/department-list') + params;
export const getMyStudentQuestionnaireStatusApi = (params = '') =>
  universityBaseEndpoint('/job-hunting-questionnaire-status-filter-list') +
  params;
export const addQuestionnaireApi = (params = '') =>
  universityBaseEndpoint('/job/job-hunting-questionnaire') + params;
export const getQuestionnairesApi = (params = '') =>
  universityBaseEndpoint('/job/job-hunting-questionnaire/all') + params;

//Company Endpoints
export const getCompaniesApi = (params = '') =>
  commonUtilsEndpoint('/v1/jobs/company/list') + params;
export const getCompaniesListApi = (params = '') =>
  universityBaseEndpoint('/company/all') + params;
export const getJobRecommondationCompaniesApi = (params = '') =>
  // commonUtilsEndpoint('/v1/jobs/company/list') + params;
  universityBaseEndpoint('/job-recommendation-company/all') + params;

//Relationship Endpoints
export const getRelationshipRequestsApi = (params = '') =>
  universityBaseEndpoint('/university/relationship-request/all') + params;
export const updateRelationshipRequestApi = () =>
  universityBaseEndpoint('/university/relationship-request');
export const getHandshakeRequestsApi = (params = '') =>
  universityBaseEndpoint('/university/handshake-request/all') + params;
export const postHandshakeRequestApi = () =>
  universityBaseEndpoint('/university/handshake-request');

//Jobs - Public Jobs Endpoints
export const getJobsCompaniesApi = (status, params = '') =>
  commonUtilsEndpoint(`/v1/${status}/jobs/company/list`) + params;
export const getInternshipCompaniesApi = (status, params = '') =>
  commonUtilsEndpoint(`/v1/${status}/internship/company/list?`) + params;
export const getJobsByCompanyIdApi = (params = '') =>
  universityBaseEndpoint('/job/all') + params;
export const getRecommondedJobsApi = (params = '') =>
  universityBaseEndpoint('/job/recommendation/all') + params;
export const postJobRecommondationApi = () =>
  universityBaseEndpoint('/job/recommendation');
export const getInterviewResultsApi = (params = '') =>
  universityBaseEndpoint('/interviews/all') + params;
export const getAppliedJobsApi = (params = '') =>
  universityBaseEndpoint('/jobApplications/all') + params;
export const getJobDescriptionByIdApi = id =>
  baseEndpoint(`/jobs/job-description/${id}`);
export const applyForJobApplicationApi = () =>
  universityBaseEndpoint('/jobs/apply');
export const getJobLocationsApi = () =>
  universityBaseEndpoint('/job-locations');
export const getJobTechnologiesApi = () => universityBaseEndpoint('/job-types');
export const getAllStudentsBasedOnUniversityApi = (params = '') =>
  universityBaseEndpoint('/students/all') + params;

//Jobs - Jobs For University
export const getPublicPublishedJobsApi = (companyId, params = '') =>
  commonUtilsEndpoint(`/v1/company/${companyId}/job/all`) + params;

export const getUniversityPublishedJobsApi = (
  universityId,
  companyId,
  params = ''
) =>
  commonUtilsEndpoint(`/v1/company/${companyId}/univ/${universityId}/job/all`) +
  params;

export const getJobsDetailsApi = (id = '') =>
  commonUtilsEndpoint('/v1/job/') + id;

export const getUniversityPublishedJobsDetailsApi = (id = '') =>
  commonUtilsEndpoint('/v1/job/') + id;

//Internship - Public Internships
export const getPublicInternshipApi = (companyId, params = '') =>
  commonUtilsEndpoint(`/v1/company/${companyId}/internship/list?`) + params;
export const getUnivInternshipApi = (companyId, params = '') =>
  commonUtilsEndpoint(`/v1/company/${companyId}/internship/list?`) + params;
export const createPublicInternshipRequestApi = () =>
  universityBaseEndpoint('/internship/request');
export const postPublicInternshipRequestApi = () =>
  universityBaseEndpoint('/internship/request');
export const getAcceptedPublicInternshipsApi = (params = '') =>
  universityBaseEndpoint('/internship/request/all') + params;
export const getDeniedPublicInternshipApi = (params = '') =>
  swaggerUniversityBaseEndpoint('/internship/recommendation/all') + params;
export const getPublicInternshipsRecommondationByStatusApi = (params = '') =>
  universityBaseEndpoint('/internship/request/all') + params;

//Intersnship - Internship for University
export const postUniversityInternshipRecommondationApi = () =>
  universityBaseEndpoint('/internship/recommendation');
export const createUniversityInternshipRecommendationApi = () =>
  universityBaseEndpoint('/internship/recommendation');
export const getUniversityInternshipApi = (params = '') =>
  universityBaseEndpoint('/internship/all') + params;
export const getUniversityInternshipRecommendationsApi = (params = '') =>
  universityBaseEndpoint('/internship/recommendation/all') + params;
export const getUniversityInternshipRecommendationsByStatusApi = (
  params = ''
) => universityBaseEndpoint('/internship/recommendation/all') + params;
export const getAcceptedUniversityInternshipsApi = (params = '') =>
  universityBaseEndpoint('/internship/all') + params;
export const getDeniedUniversityInternshipApi = (params = '') =>
  universityBaseEndpoint('/internship/all') + params;
export const getInternalInternshipInterviewResultApi = (params = '') =>
  universityBaseEndpoint('/internship/all') + params;
export const applyForInternshipForUniversityRecommondationApi = (params = '') =>
  universityBaseEndpoint('/internship/all') + params;

//Interview Endpoints internship/interview-update
export const getInternshipInterviewsRequestApi = (params = '') =>
  universityBaseEndpoint('/internship/request/all') + params;
export const updateInternshipInterviewApi = id =>
  universityBaseEndpoint(`/internship/interview-update/${id}`);
export const updateJobInterviewApi = (params = '') =>
  universityBaseEndpoint('/job-interview/') + params;
export const getInternshipAgreementApi = (params = '') =>
  universityBaseEndpoint('/internship/agreement/all') + params;
export const rescheduleInterviewApi = () =>
  universityBaseEndpoint('/interviews/reschedule');
export const acceptInterviewApi = () =>
  universityBaseEndpoint('/interviews/accept');
export const rejectInterviewApi = () =>
  universityBaseEndpoint('/interviews/reject');

//Job Offer Letter Endpoints
export const getJobOfferLettersApi = (params = '') =>
  universityBaseEndpoint('/job-offers/all') + params;
export const updateJobOfferStatusApi = () =>
  universityBaseEndpoint('/job-offer/');
export const submitFeedbackApi = () =>
  universityBaseEndpoint('/job-offer/feedback');

//Favorites Endpoints
export const toggleFavoriteJobApi = () =>
  universityBaseEndpoint('/job/favourite');
export const getAllFavoriteJobsApi = () =>
  universityBaseEndpoint('/favorite/jobs');
export const updateFavoriteSemiarApi = () =>
  universityBaseEndpoint('/seminar/favourite');
export const getFavoriteSeminarApi = () =>
  universityBaseEndpoint('/favorite/seminars');

//Seminar Endpoints
export const getSeminarsByCompanyApi = (seminarCategory, params = '') =>
  universityBaseEndpoint(`/seminar/${seminarCategory}/all`) + params;
export const getSeminarsListByCompanyApi = (params = '') =>
  universityBaseEndpoint('/seminar/all') + params;
export const getSeminarApplicationsApi = (params = '') =>
  universityBaseEndpoint('/seminar/applications/all') + params;
export const submitSeminarRequestApi = () =>
  universityBaseEndpoint('/seminar/request');
export const createSeminarRecommendationApi = () =>
  universityBaseEndpoint('/seminar/recommendation');
export const getAllSeminarRecommendationsApi = (params = '') =>
  universityBaseEndpoint('/seminar/recommendation/all') + params;
export const getAllAttendedSeminarsApi = (params = '') =>
  universityBaseEndpoint('/seminar/recommendation/all') + params;

/**
 * Retrieves a list of public seminars from the university API.
 *
 * @param {string} params - Additional query parameters for customizing the API request (optional).
 * @returns {string} - The complete API endpoint URL for fetching public seminars.
 */
export const getPublicSeminarsApi = (params = '') =>
  universityBaseEndpoint(`/seminar/${SEMINAR_CATEGOREIES.PUBLIC}/all`) + params;

/**
 * Retrieves a list of filtered public seminars associated with a specific company.
 *
 * @param {string} companyId - The unique identifier of the company.
 * @param {string} params - Additional query parameters (optional).
 * @returns {string} - The API endpoint URL for retrieving filtered public seminars.
 */
export const getFilteredPublicSeminarsApi = (companyId, params = '') =>
  commonUtilsEndpoint(`/v1/saved/seminar/findall` + params);
// universityBaseEndpoint(
//   `/company/${companyId}/${SEMINAR_CATEGOREIES.PUBLIC}/seminars`
// ) + params;

/**
 * Retrieves a list of public seminars by their request status for a specific company.
 *
 * @param {string} type - The type of seminar request status (e.g., 'accepted', 'pending', 'rejected').
 * @param {string} companyId - The unique identifier of the company.
 * @param {string} params - Additional query parameters (optional).
 * @returns {string} - The API endpoint URL for retrieving public seminars by status.
 */
export const getPublicSeminarsByStatusApi = (type, companyId, params = '') =>
  universityBaseEndpoint(`/seminar/${type}/request/status/${companyId}`) +
  params;

/**
 * Retrieves a list of students who attended a specific public seminar organized by a company.
 *
 * @param {string} type - The type of seminar (e.g., 'webinar', 'workshop', 'conference').
 * @param {string} companyId - The unique identifier of the company.
 * @param {string} seminarId - The unique identifier of the seminar.
 * @param {string} params - Additional query parameters (optional).
 * @returns {string} - The API endpoint URL for retrieving attended students of a public seminar.
 */
export const getPublicSeminarAttendedStudentsApi = (
  type,
  companyId,
  seminarId,
  params = ''
) =>
  universityBaseEndpoint(
    `/seminar/${type}/${companyId}/${seminarId}/view/attended/students`
  ) + params;

/**
 * Retrieves a list of seminars that were denied based on their request status for a specific type.
 *
 * @param {string} type - The type of seminar (e.g., 'webinar', 'workshop', 'conference').
 * @param {string} params - Additional query parameters (optional).
 * @returns {string} - The API endpoint URL for retrieving denied seminars of the specified type.
 */
export const getDeniedSeminarsApi = (type, params = '') =>
  universityBaseEndpoint(`/seminar/${type}/request/${STATUS.DENY}`) + params;
export const submitSendBookingApi = id =>
  universityBaseEndpoint(`/seminar/${id}/sendBooking`);
export const submitSeminarBookingRequestApi = id =>
  universityBaseEndpoint(`/seminar/${id}/booking/request`);

/**
 * Retrieves a list of companies offering seminars along with the status.
 *
 * @param {string} type - The type of seminars to filter (e.g., "public," "university").
 * @param {string} status - The status of seminars to filter (e.g., "accepted," "attended," "requested", "deny").
 * @param {string} [params=''] - Additional query parameters to include in the API request.
 * @returns {string} The API endpoint URL for fetching companies with seminar status.
 */
export const getCompaniesWithSeminarStatusApi = (type, status, params = '') =>
  universityBaseEndpoint(`/company/seminar/${type}/request/${status}`) + params;
export const getSeminarBookingRequestApi = (bookingStatus, seminarReqId) =>
  universityBaseEndpoint(
    `/seminar/bookedroom/${seminarReqId}/${bookingStatus}/details`
  );
export const getPublicDeniedSeminarsApi = (params = '') =>
  universityBaseEndpoint(
    `/seminar/${SEMINAR_CATEGOREIES.PUBLIC}/request/denied/list`
  ) + params;

//Semianr For University Endpoints
export const getUniversitySeminarCompaniesApi = (univId, status, params = '') =>
  commonUtilsEndpoint(`/v1/univ/${univId}/${status}/seminar/company/findAll?`) +
  params;
export const getUniversitySeminarsByCompanyApi = (
  compId,
  univId,
  params = ''
) =>
  universityBaseEndpoint(
    `/company/${compId}/${SEMINAR_CATEGOREIES.UNIVERSITY}/${univId}/seminars`
  ) + params;
export const getUniversitySeminarCompaniesByStatusApi = (
  univId,
  status,
  params = ''
) =>
  universityBaseEndpoint(
    `/company/seminar/${SEMINAR_CATEGOREIES.UNIVERSITY}/${univId}/request/${status}`
  ) + params;
export const getUniversitySeminarsByCompanyAndStatusApi = (
  compId,
  univId,
  status,
  params = ''
) =>
  universityBaseEndpoint(
    `/company/${compId}/seminar/${SEMINAR_CATEGOREIES.UNIVERSITY}/${univId}/request/${status}`
  ) + params;
export const getStudentsAttendingUniversitySeminarApi = (
  compId,
  seminarId,
  univId,
  params = ''
) =>
  universityBaseEndpoint(
    `/seminar/${SEMINAR_CATEGOREIES.UNIVERSITY}/${compId}/${seminarId}/${univId}/view/attended/students`
  ) + params;
export const getUniversityDeniedSeminarsApi = (univId, params = '') =>
  universityBaseEndpoint(
    `/seminar/${SEMINAR_CATEGOREIES.UNIVERSITY}/univ/${univId}/request/denied/list`
  ) + params;

//Seminar - Filters Endpoints
export const getSeminarTitlesApi = () =>
  universityFilterBaseEndpoint('/seminar-title/list');
export const getSeminarLocationsApi = () =>
  universityFilterBaseEndpoint('/location/list');
export const getRequestedSeminarListApi = () =>
  universityFilterBaseEndpoint('/requested/seminars/list');
export const getSeminarIndustryListApi = () =>
  universityFilterBaseEndpoint('/industry/list');
export const getCompanyNamesApi = () =>
  universityFilterBaseEndpoint('/company/list');

// Auth Endpoints
export const loginUserApi = () =>
  universityBaseEndpoint('/auth/student/signin');
export const loginSSOUserApi = () => universityBaseEndpoint('/login');
export const logoutSSOUserApi = () => universityBaseEndpoint('/logout');
export const signupUserApi = () =>
  universityBaseEndpoint('/auth/university/signup');
export const resetPasswordApi = () =>
  universityBaseEndpoint('/auth/student/reset-password');
export const getAllCoursesApi = (params = '') =>
  universityBaseEndpoint('/course/all') + params;

// Setting Endpoints
export const getUserSettingsApi = (params = '') =>
  universityBaseEndpoint('/settings') + params;
export const updateUserSettingsApi = () => universityBaseEndpoint('/settings');

//Notification Endpoint
export const getNotificationsApi = (params = '') =>
  universityBaseEndpoint(`/notification`) + params;
export const deleteNotificationApi = univId =>
  universityBaseEndpoint(`/notification/clear/${univId}`);

//Profile Endpoints
export const getCertificateIssuingOrgListApi = () =>
  universityBaseEndpoint('/certificate-organization-list');
export const getCertificationListApi = () =>
  universityBaseEndpoint('/faculty-department-list');
export const getFacultyDepartmentListApi = () =>
  universityBaseEndpoint('/faculty-department-list');
export const getSchoolUniversityListApi = () =>
  universityBaseEndpoint('/school-university-list');
export const editStudentProfileApi = user =>
  universityBaseEndpoint(`/university/edit-profile/${user}`);
export const getStudentProfileApi = () =>
  universityBaseEndpoint(`/university/get-user-profile`);
export const saveProfileApi = () =>
  universityBaseEndpoint('/university/create-profile');
export const editUniversityProfileApi = id =>
  universityBaseEndpoint(`/university/edit-profile/${id}`);

//Help and Feedback Endpoints
export const createHelpRequestApi = () =>
  universityBaseEndpoint('/request-help');
export const createFeedbackRequestApi = () =>
  universityBaseEndpoint('/submit-feedback');

//Scholarship Endpoints
export const getScholarshipsApi = (params = '') =>
  universityBaseEndpoint('/university/company-scholarship/all') + params;
export const getScholarshipRequestApi = (params = '') =>
  universityBaseEndpoint('/university/company-scholarship-request/all') +
  params;
export const getScholarshipInvoiceApi = (params = '') =>
  universityBaseEndpoint('/university/company-scholarship-invoice') + params;
export const createScholarshipRequestApi = () =>
  universityBaseEndpoint('/university/company-scholarship-request');
export const createScholarshipInvoiceApi = () =>
  universityBaseEndpoint('/university/company-scholarship-invoice-receipt');
export const getScholarshipReceipteApi = (params = '') =>
  universityBaseEndpoint('/university/company-scholarship-invoice-receipt') +
  params;

// Internship
export const getInterviewsApi = (params = '') =>
  universityBaseEndpoint('/interviews/all') + params;

// MS Teams
// Internship
export const msTeamsBaseApi = (params = '') =>
  'https://graph.microsoft.com/v1.0' + params;
export const getMyCalendarApi = (params = '') =>
  msTeamsBaseApi('/me/calendar/events' + params);
export const getMyChatsApi = (params = '') =>
  msTeamsBaseApi('/me/chats' + params);
export const getChatUsersApi = (chatId = '') =>
  msTeamsBaseApi(`/chats/${chatId}/members`);
export const getChatMessagesApi = (chatId = '') =>
  msTeamsBaseApi(`/chats/${chatId}/messages`);
export const getMyProfileApi = (chatId = '') => msTeamsBaseApi(`/me`);
export const getUserProfileImageApi = (userId = '') =>
  msTeamsBaseApi(`/users/${userId}/photo/$value`);
export const getAllTeamsUsersApi = (params = '') =>
  msTeamsBaseApi(`/users${params}`);
export const sendMessageApi = (chatId = '') =>
  msTeamsBaseApi(`/chats/${chatId}/messages`);
export const createChatApi = () => msTeamsBaseApi(`/chats`);

//Admin -Users
export const getAllUsersApi = (params = '') =>
  universityAdminBaseEndpoint('/auth/user/all') + params;
export const addUserApi = () => universityAdminBaseEndpoint('/auth/add-user');
export const editUserApi = () =>
  universityAdminBaseEndpoint('/auth/edit-user-profile');
export const deleteUserApi = idToDelete =>
  universityAdminBaseEndpoint(`/auth/delete-user/${idToDelete}`);

//Admin - Subscription Plans
export const getAllSubscriptionPlansApi = (params = '') =>
  universityAdminBaseEndpoint('/plans/all') + params;
export const getSubscribedPlanApi = (params = '') =>
  universityAdminBaseEndpoint('/subscribed-plan/all') + params;

//University Profile
export const getSeminarCompainesList = (status, params = '') =>
  commonUtilsEndpoint(`/v1/${status}/seminar/company/findAll?${params}`);

export const getCompanySeminarsList = (companyId, params = '') =>
  commonUtilsEndpoint(`/v1/company/${companyId}/seminar/findAll?${params}`);
export const getUniCompanySeminarsList = (
  universityId,
  companyId,
  params = ''
) =>
  commonUtilsEndpoint(
    `/v1/univ/${universityId}/company/${companyId}/seminar/findAll?${params}`
  );
