export const LS_USER_ID = 'USER_ID';
export const LS_SUBSCRIBER_STUDENT_ID = 'SUBSCRIBER_STUDENT_ID';
export const LS_LANGUAGE = 'LANGUAGE';
export const LS_TIMEZONE = 'TIMEZONE';
export const LS_DATEFORMAT = 'DATEFORMAT';
export const LS_EMAIL = 'EMAIL';
export const LS_USER_PROFILE_LOCKED = 'USER_PROFILE_LOCKED';
export const LS_ID_TOKEN = 'ID_TOKEN';
export const LS_ACCESS_TOKEN = 'ACCESS_TOKEN';
export const LS_ADMIN_USER = 'ADMIN_USER';
export const STUDENT_ID = localStorage.getItem(LS_USER_ID);
export const LS_USER_NAME = 'USER_NAME';
export const LS_UNIVERSITY_NAME = 'UNIVERSITY_NAME';
export const LS_USER_PROFILE_DATA = 'USER_PROFILE_DATA';

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key, defaultValue = null) => {
  const storedValue = localStorage.getItem(key);

  if (storedValue === null || storedValue === undefined) {
    return defaultValue;
  }

  return JSON.parse(storedValue);
};
