import { LOGIN_CALLBACK, SIGNOUT } from './constants/routes';

export const TENANT_ID = 'a3edf391-06af-4ead-a840-222154e2dd34';
export const CLIENT_ID = 'e44eb689-6f9f-4cde-bb75-fbdc51ac9193';
export const CLIENT_SECRET = '1L38Q~..jCI2W1ysnMxKgyoV5cKNctz6Lddzhb.8';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    // clientId: 'e44eb689-6f9f-4cde-bb75-fbdc51ac9193',
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: LOGIN_CALLBACK,
    postLogoutRedirectUri: SIGNOUT,
    clientSecret: CLIENT_SECRET,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
