import axios from 'axios';
import { LS_ID_TOKEN } from 'utils/constants/localStorage';
const axiosInstance = axios.create({
  // baseURL: environments.serverHost,
});

const isHandlerEnabled = (config = {}) => {
  return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled);
};

const requestHandler = request => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    request.headers['Access-Control-Allow-Origin'] = '*';
    const token = localStorage.getItem(LS_ID_TOKEN);
    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return request;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));

const errorHandler = error => {
  if (error.response.status === 401) {
    console.log(
      'Unauthorized: You do not have permission to access this resource'
    );
  }
  return Promise.reject(error.response.data);
};

const successHandler = response => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
    return response.data;
  } else return response;
};

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);

export default axiosInstance;
