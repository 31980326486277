import {
  CardTravel,
  Dashboard,
  Diversity3,
  GroupOutlined,
  HelpOutline,
  Home,
  Logout,
  ManageAccounts,
  NotificationsNoneOutlined,
  PersonOutline,
  Settings,
  WorkHistory,
  WorkOutlineOutlined,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  ADMIN_PROFILE,
  APP_SELECT,
  CAREER_OFFICE_MY_PROFILE,
  CHAT,
  DASHBOARD,
  DASHBOARD_APPLICATION_STATUS,
  DASHBOARD_QUESTIONNAIRE,
  HELP_AND_FEEDBACK,
  INTERNSHIP_AGREEMENT,
  INTERNSHIP_INTERVIEW,
  INTERNSHIP_STUDENTS_STATUS,
  JOBS_FOR_UNIVERSITY,
  JOB_HUNTING_STATUS,
  MANAGE_SUBSCRIPTIONS,
  MANAGE_USERS,
  NOTIFICATION,
  PUBLIC_INTERNSHIPS,
  PUBLIC_JOBS,
  PUBLIC_SEMINARS,
  RELATIONSHIP_DELETED,
  RELATIONSHIP_HANDSHAKE,
  RELATIONSHIP_REQUEST,
  SCHOLARSHIP_APPLICATION,
  SCHOLARSHIP_COMPANIES_OFFERS,
  SETTINGS,
  SIGNOUT,
  SPONSORS,
  TEAMS_APP,
  UNIVERSITY_INTERNSHIP,
  UNIVERSITY_SEMINARS,
} from './routes';

export const DEFAULT_APP_NAME = 'UNIVERSITY_APP';
export const DEFAULT_UNIVERSITY_NAME = '';
export const APP_BAR_HEIGHT = 50;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_PAGINATION = {
  data: [],
  page: DEFAULT_PAGINATION_PAGE_NO,
  count: DEFAULT_PAGINATION_COUNT,
  total: 0,
  next: false,
  previous: false,
};

export const DEFAULT_CLAMP_WORD_SIZE = 10;
export const EMPTY_FIELD_INDICATOR = '-';

export const statusStyles = {
  default: {
    backgroundColor: 'primary.lighter',
    color: 'primary.main',
  },
  success: {
    backgroundColor: '#D4FFDD',
    color: '#17AF38',
  },
  error: {
    backgroundColor: '#FFEDED',
    color: 'error.main',
  },
};

export const NOTIFICATION_TYPE = {
  JOBS: 'JOBS',
  SEMINARS: 'SEMINARS',
  INTERNSHIPS: 'INTERNSHIPS',
};

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';
export const DEFAULT_FACULTY = 'Faculty of Law Department of Law';
export const DEFAULT_DEPARTMENT = 'Department of Law';
export const DRAWER_MENU = [
  {
    id: uuidv4(),
    title: 'Home',
    url: APP_SELECT,
    icon: Home,
  },
  {
    id: uuidv4(),
    title: 'Dashboard',
    url: DASHBOARD,
    icon: Dashboard,
  },
  {
    id: uuidv4(),
    title: 'Career Office',
    icon: WorkOutlineOutlined,
    url: CAREER_OFFICE_MY_PROFILE,
    items: [
      // {
      //   id: 716,
      //   title: 'Teams App',
      //   url: TEAMS_APP,
      //   icon: ChatBubbleOutlineRounded,
      // },
      {
        id: uuidv4(),
        title: 'My Profile',
        url: CAREER_OFFICE_MY_PROFILE,
      },
      {
        id: uuidv4(),
        title: 'Chat Room',
        url: CHAT,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'My Students',
    icon: GroupOutlined,
    url: JOB_HUNTING_STATUS,
    items: [
      {
        id: uuidv4(),
        title: 'Job Hunting',
        url: JOB_HUNTING_STATUS,
      },
      {
        id: uuidv4(),
        title: 'Internship Students',
        url: INTERNSHIP_STUDENTS_STATUS,
      },
      {
        id: uuidv4(),
        title: 'Send Questionnaire',
        url: DASHBOARD_QUESTIONNAIRE,
      },
      {
        id: uuidv4(),
        title: 'Questionnaires Result',
        url: DASHBOARD_APPLICATION_STATUS,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Seminar',
    icon: Diversity3,
    url: PUBLIC_SEMINARS,
    items: [
      {
        id: uuidv4(),
        title: 'Public Seminar',
        url: PUBLIC_SEMINARS,
      },
      {
        id: uuidv4(),
        title: 'Seminar for University',
        url: UNIVERSITY_SEMINARS,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Jobs',
    icon: CardTravel,
    url: PUBLIC_JOBS,
    items: [
      {
        id: uuidv4(),
        title: 'Public Job',
        url: PUBLIC_JOBS,
      },
      {
        id: uuidv4(),
        title: 'Jobs for University',
        url: JOBS_FOR_UNIVERSITY,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Internship',
    icon: WorkHistory,
    url: PUBLIC_INTERNSHIPS,
    items: [
      {
        id: uuidv4(),
        title: 'Public Internship',
        url: PUBLIC_INTERNSHIPS,
      },
      {
        id: uuidv4(),
        title: 'Internship for University',
        url: UNIVERSITY_INTERNSHIP,
      },
      {
        id: uuidv4(),
        title: 'Internship Interview',
        url: INTERNSHIP_INTERVIEW,
      },
      {
        id: uuidv4(),
        title: 'Internship Agreement',
        url: INTERNSHIP_AGREEMENT,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Admin',
    icon: ManageAccounts,
    url: MANAGE_USERS,
    items: [
      {
        id: uuidv4(),
        title: 'Manage Users',
        url: MANAGE_USERS,
      },
      {
        id: uuidv4(),
        title: 'Manage Subscription',
        url: MANAGE_SUBSCRIPTIONS,
      },
      // {
      //   id: uuidv4(),
      //   title: 'University Profile',
      //   url: ADMIN_PROFILE,
      // },
      {
        id: uuidv4(),
        title: 'Relationship Request',
        url: RELATIONSHIP_REQUEST,
      },
      {
        id: uuidv4(),
        title: 'Handshake',
        url: RELATIONSHIP_HANDSHAKE,
      },
      {
        id: uuidv4(),
        title: 'Deleted Relationship',
        url: RELATIONSHIP_DELETED,
      },
      {
        id: uuidv4(),
        title: 'Apply Scholarships',
        url: SCHOLARSHIP_COMPANIES_OFFERS,
      },
      {
        id: uuidv4(),
        title: 'Apply Donation',
        url: SCHOLARSHIP_APPLICATION,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Profile',
    icon: PersonOutline,
    url: ADMIN_PROFILE,
    items: [
      {
        id: uuidv4(),
        title: 'University Profile',
        url: ADMIN_PROFILE,
      },
      {
        id: uuidv4(),
        title: 'Sponsors',
        url: SPONSORS,
      },
    ],
  },
  {
    id: uuidv4(),
    title: 'Notification',
    url: NOTIFICATION,
    icon: NotificationsNoneOutlined,
  },
  {
    id: uuidv4(),
    title: 'Help and Feedback',
    url: HELP_AND_FEEDBACK,
    icon: HelpOutline,
  },
  {
    id: uuidv4(),
    title: 'Settings / 設定',
    url: SETTINGS,
    icon: Settings,
  },
  {
    id: uuidv4(),
    title: 'Sign Out',
    url: SIGNOUT,
    icon: Logout,
    type: 'dialog',
  },
];

export const tableHeaderStyles = {
  fontSize: '0.65rem',
  fontFamily: 'Poppins',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  color: '#999',
  fontWeight: 600,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: '#1A1A1A',
  flex: 1,
};

export const tableTextLinkStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'blue.500',
};

export const cardStyles = {
  textClamp: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
};

export const paginationState = {
  previous: null,
  next: null,
  noOfPageItems: DEFAULT_PAGINATION_COUNT,
  page: DEFAULT_PAGINATION_PAGE_NO,
  total: DEFAULT_PAGINATION_COUNT * (DEFAULT_PAGINATION_PAGE_NO + 1),
  pageCount: DEFAULT_PAGINATION_PAGE_NO + 1,
  hasMore: false,
  loading: true,
  data: [],
};

export const CARD_VIEW = 0;
export const LIST_VIEW = 1;
export const VIEW_TYPES_MAP = {
  [CARD_VIEW]: 'Card View',
  [LIST_VIEW]: 'List View',
};
export const VIEW_TYPES = Object.values(VIEW_TYPES_MAP);
export const DEFAULT_VIEW_TYPE = LIST_VIEW;

export const DEFAULT_LANGUAGE = 'ja';

export const LANGUAGES = [
  {
    text: 'Japanese',
    value: 'ja',
  },
  {
    text: 'English',
    value: 'en',
  },
];
export const INTERVIEW_INVITATION_STATUS_ACCEPTED = 0;
export const INTERVIEW_INVITATION_STATUS_DENY = 1;
export const INTERVIEW_INVITATION_STATUS_RESCHEDULE = 2;

export const INTERVIEW_INVITATION_STATUS = {
  [INTERVIEW_INVITATION_STATUS_ACCEPTED]: 'Accepted',
  [INTERVIEW_INVITATION_STATUS_DENY]: 'Rejected',
  [INTERVIEW_INVITATION_STATUS_RESCHEDULE]: 'Reschedule',
};

export const JOB_OFFER_STATUS_ACCEPTED = 'Accepted';
export const JOB_OFFER_STATUS_DENIED = 'Denied';

export const JOB_OFFER_STATUS = {
  [JOB_OFFER_STATUS_ACCEPTED]: 'Accepted',
  [JOB_OFFER_STATUS_DENIED]: 'Denied',
};

export const BREAK_RELATIONSHIP = {
  0: 'Ligula vitae id aliquam laoreet malesuada ',
  1: 'Ligula vitae id aliquam laoreet malesuada h',
  2: 'Ligula vitae id aliquam laoreet malesuada "',
  3: 'Ligula vitae id aliquam laoreet malesuada ',
  4: 'Others',
};

export const BREAK_RELATIONSHIP_OPTIONS = Object?.keys(BREAK_RELATIONSHIP).map(
  o => ({
    value: o,
    text: BREAK_RELATIONSHIP[o],
  })
);

export const ONLINE = 0;
export const OFFLINE = 1;
export const BOTH = 2;
export const MODE_TYPE_MAP = {
  [ONLINE]: 'Online',
  [OFFLINE]: 'Offline',
  [BOTH]: 'Both',
};
export const MODE_TYPE = Object.values(MODE_TYPE_MAP);

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const DEBOUNCE_DELAY = 1000;

export const DONATION_TYPE = [
  {
    name: 'Campus Maintenance/Renewal',
    value: 'campusMaintenance',
  },
  {
    name: 'Other',
    value: 'other',
  },
];

export const INVOICE_ITEM = [
  {
    name: 'Scholarship',
    value: 'scholarship',
  },
  {
    name: 'Donation',
    value: 'donation',
  },
];

export const CURRENCY = [
  {
    name: 'INR',
    value: 'inr',
  },
  {
    name: '¥',
    value: 'yen',
  },
];

export const FILTER_OPTIONS = {
  LOCATION: 'location',
  JOB_TITLE: 'jobTitle',
  RECOMMENDED_DATE: 'recommendedDate',
  REQUESTED_DATE: 'requestedDate',
  DENIED_DATE: 'deniedDate',
  DEADLINE: 'deadline',
  FACULTY: 'faculty',
  STATUS: 'isActive',
  INDUSTRY: 'industry',
  DENIED_SEMINARS: 'deniedSeminars',
  TITLE: 'title',
  STYLE: 'style',
  ENTRY_DEADLINE: 'entryDeadline',
  POSTED_JOB: 'postedJobs',
  RESULT: 'result',
  AGREEMENT_REQUEST_DATE: 'agreementRequestDate',
  RESPOND_BY: "respondDate"
};

export const menuJapStyles = {
  fontFamily: 'Noto Sans JP',
  fontSize: 14,
  fontWeight: 700,
};

export const subMenuJapStyles = {
  fontFamily: 'Noto Sans JP',
  fontSize: 12,
  fontWeight: 600,
};
