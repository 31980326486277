import MainLoader from 'components/common/MainLoader';
import React, { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import '@fontsource/righteous';
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/raleway/100.css';
import '@fontsource/raleway/200.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/800.css';
import '@fontsource/raleway/900.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import 'translations';
import 'index.css';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { theme } from 'utils/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppProvider from 'contexts/AppProvider';
import UserProvider from 'contexts/UserProvider';
import SnackbarCloseButton from 'features/snackbar/SnackBarCloseButton';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider } from 'contexts/AuthProvider';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'utils/msalConfig';
import { MsTeamsProvider } from 'contexts/MsTeamsProvider';

const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const pca = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 30000,
      refetchOnWindowFocus: true,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={3000}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            action={snackbarKey => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <MsalProvider instance={pca}>
              <AuthProvider>
                <MsTeamsProvider>
                  <AppProvider>
                    <UserProvider>
                      <CssBaseline />
                      <Suspense fallback={<MainLoader />}>
                        <App />
                      </Suspense>
                    </UserProvider>
                  </AppProvider>
                </MsTeamsProvider>
              </AuthProvider>
            </MsalProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();