import { ExploreTwoTone } from '@mui/icons-material';
import { DEFAULT_CLAMP_WORD_SIZE, DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_DATEFORMAT, LS_LANGUAGE } from 'utils/constants/localStorage';
import { DEFAULT_DATE_PATTERN } from 'utils/constants/settings';

/**
 * A no-operation function used as a placeholder for future functionality.
 */
export const noop = () => {
  // This function intentionally does nothing.
};

export const arrayIndexSequencePicker = (index, length = 1) => index % length;

export const paginationCounter = (total = 0, noOfPageItems = 30) => {
  return Math.ceil(total / noOfPageItems);
};

export const getLanguageCode = () => {
  return localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE;
};

export const getDateFormatPattern = () => {
  return localStorage.getItem(LS_DATEFORMAT) || DEFAULT_DATE_PATTERN;
};

export const clampText = (text, maxWords = DEFAULT_CLAMP_WORD_SIZE) => {
  const words = text.split(' ');
  if (words.length <= maxWords) {
    return text;
  }
  return words.slice(0, maxWords).join(' ') + '...';
};

// Function to capitalize each word
export const capitalizeWords = str => {
  return str.replace(/\b\w/g, match => match.toUpperCase());
};

export const scrollToElement = elementId => {
  var element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export const responseObjectToArray = data => {
  return Object.entries(data).map(([key, value]) => {
    // Splitting the key string to extract key-value pairs
    const keyValuePairs = key.match(/\w+=(?:\w+|'[^']*')/g);
    const parsedObject = {};
    keyValuePairs.forEach(pair => {
      const [k, v] = pair.split('=');
      parsedObject[k] = v.replace(/'/g, ''); // Remove single quotes if present
    });

    return parsedObject;
  });

  // return Object.values(data).map(o => JSON.parse(o));
};
