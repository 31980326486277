import { format } from 'date-fns';
import moment from 'moment';
import { getDateFormatPattern, getLanguageCode } from '.';
import { EMPTY_FIELD_INDICATOR } from 'utils/constants/app';
const PATTERN = 'YYYY-MM-DD HH:mm:ss';

export const formatToDate = timestamp => {
  const lng = getLanguageCode();
  const dateFormatPattern = getDateFormatPattern();

  const momentDate = moment(timestamp);

  // Check if the momentDate is valid
  if (momentDate.isValid()) {
    return momentDate.locale(lng).format(dateFormatPattern);
  } else {
    // Return "EMPTU_TEXT_INDICATOR" for invalid dates
    return EMPTY_FIELD_INDICATOR; // Make sure EMPTU_TEXT_INDICATOR is defined somewhere
  }
};

export const formattedDate = date => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatToMonth = date => {
  const lng = getLanguageCode();
  return moment(date).locale(lng).format('MMMM ');
};

export const formatRelativeTime = timestamp => {
  const lng = getLanguageCode();
  return moment(timestamp).locale(lng).fromNow();
};

export const formattoEnglishDate = timestamp => {
  const lng = getLanguageCode();
  return moment(timestamp).locale(lng).format('ll');
};

export const formatToLocalTime = timestamp => {
  const lng = getLanguageCode();
  return moment(timestamp).locale(lng).format('hh');
};

export const formatExpiryDate = date => {
  const now = moment(new Date()); //todays date
  const end = moment(date); // another date
  const duration = moment.duration(now.diff(end));
  const days = duration.asDays();
  return days;
};

export const formatDateWithPattern = (date, pattern) => {
  return format(date, pattern);
};

export const simpleDateFormat = date => {
  return format(date, PATTERN);
};

export const createPreviousCustomDate = (value = 0, unit = 'months') => {
  return moment()
    .subtract(value, unit)
    .utcOffset(0)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const apiDateFormatter = m => {
  if (!m) return undefined;
  return moment(m.toISOString()).format(API_DATE_FORMAT);
};

export const monthsArrayWithZeroGenerator = () => {
  const months = [...new Array(12)].map(o => o + 1);
  return months;
};

export const MONTH_LABEL_MAPPING_SHORT = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'May',
  5: 'Apr',
  6: 'Jun',
  7: 'July',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const formattedDateForDB = date => {
  return moment(date).valueOf();
};

export const formattedTime = dateTimeString => {
  return moment(dateTimeString).format('LT');
};

export const DEFAULT_CURRENT_DATE = new Date().toISOString().split('T')[0];

export const getMonthStartEndDates = (month, year) => {
  const startDate = moment({ year, month }).startOf('month').startOf('day');
  const endDate = moment({ year, month }).endOf('month').endOf('day');

  return {
    startDate: apiDateFormatter(startDate),
    endDate: apiDateFormatter(endDate),
  };
};

export const calenderViewDateTimeFormat = date => {
  return moment().utc(date).toDate();
};

export const formatTimeRange = (startTime, endTime) => {
  const formatOptions = 'h:mm a'; // AM/PM format
  const formattedStartTime = format(new Date(startTime), formatOptions);
  const formattedEndTime = format(new Date(endTime), formatOptions);

  return `${formattedStartTime} - ${formattedEndTime}`;
};

export const formatSeminarSchedule = (startTime, endTime) => {
  const dateFormat = 'MMM d, yyyy'; // Format for the date (e.g., Jul 30, 2022)
  const timeFormat = 'h:mm a'; // Format for the time (e.g., 10am)

  const formattedStartDate = format(new Date(startTime), dateFormat);
  const formattedStartTime = format(new Date(startTime), timeFormat);
  const formattedEndTime = format(new Date(endTime), timeFormat);

  return `${formattedStartDate} - ${formattedStartTime} TO ${formattedEndTime}`;
};
