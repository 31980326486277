import { createContext, useContext, useEffect, useState } from 'react';
import { noop } from 'utils/helpers';
import {
  createChatApi,
  getAllTeamsUsersApi,
  getChatMessagesApi,
  getChatUsersApi,
  getMyCalendarApi,
  getMyChatsApi,
  getMyProfileApi,
  getUserProfileImageApi,
  sendMessageApi,
} from 'utils/constants/apis';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import { calenderViewDateTimeFormat } from 'utils/helpers/date';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SKIP,
  paginationState,
} from 'utils/constants/msTeams';
import { getUserId } from 'utils/helpers/auth';
import {
  convertMsImageToImageSrc,
  generateInitialsAvatar,
} from 'utils/helpers/image';

const MsTeamsContext = createContext({
  loading: false,
  messagesLoading: false,
  chatsLoading: false,
  usersLoading: false,
  myCalendar: [],
  myChats: paginationState,
  chatMessages: paginationState,
  myProfile: null,
  chatId: null,
  users: paginationState,
  getMyCalender: noop,
  getMyChats: noop,
  clearChats: noop,
  getOnetoOneChatUsersDetails: noop,
  getChatMessages: noop,
  clearChatMessages: noop,
  getMyProfile: noop,
  getUserProfileImage: noop,
  sendMessage: noop,
  createChatId: noop,
  getAllUsers: noop,
  clearAllUsers: noop,
  createChatOneOnOne: noop,
  createGroupChat: noop,
});

export const useMsTeams = () => useContext(MsTeamsContext);

export const MsTeamsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [myCalendar, setMyCalendar] = useState([]);
  const [myChats, setMyChats] = useState(paginationState);
  const [chatId, setChatId] = useState(null);
  const [chatMessages, setChatMessages] = useState(paginationState);
  const [users, setUsers] = useState(paginationState);
  const [myProfile, setMyProfile] = useState();
  const { accessToken } = useAuth();
  const [profileImages, setProfileImages] = useState({});

  const headers = { Authorization: `Bearer ${accessToken}` };

  useEffect(() => {
    getMyProfile();
  }, []);

  const getMyProfile = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axios
      .get(getMyProfileApi(), {
        headers,
      })
      .then(({ data }) => {
        setMyProfile(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserProfile = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axios
      .get(getMyProfileApi(), {
        headers,
      })
      .then(({ data }) => {
        setMyProfile(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserProfileImage = (
    userId,
    displayName,
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);

    if (profileImages?.hasOwnProperty(userId)) {
      onSuccess(profileImages[userId]);
      return;
    }

    axios
      .get(getUserProfileImageApi(userId), {
        headers,
        responseType: 'blob',
      })
      .then(res => {
        const imageSrc = convertMsImageToImageSrc(res.data);
        setProfileImages(o => ({
          ...o,
          [userId]: imageSrc,
        }));
        onSuccess(imageSrc);
      })
      .catch(err => {
        if (err.response.status === 404) {
          const imageSrc = generateInitialsAvatar(displayName);
          setProfileImages(o => ({
            ...o,
            [userId]: imageSrc,
          }));
          onSuccess(imageSrc);
        } else {
          onError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllUsers = (
    starting = true,
    search = '',
    onSuccess = noop,
    onError = noop
  ) => {
    setUsersLoading(true);
    let filters = '';
    if (search) {
      filters = `?$filter=startswith(displayName,'${search}') or startswith(mail,'${search}')`;
    }

    axios
      .get(getAllTeamsUsersApi(filters), {
        headers,
      })
      .then(({ data }) => {
        setUsers(o => ({
          ...o,
          next: data['@odata.nextLink'],
          data: starting
            ? [...data?.value?.reverse()]
            : [...data?.value?.reverse(), ...o.data],
        }));
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setUsersLoading(false);
      });
  };

  const clearAllUsers = () => {
    setUsers(paginationState);
  };

  const getMyCalender = (
    startDate,
    endDate,
    onSuccess = noop,
    onError = noop
  ) => {
    if (!startDate || !endDate) return;

    const pageSize = DEFAULT_PAGE_SIZE;
    const skip = DEFAULT_PAGE_SKIP;

    setLoading(true);
    axios
      .get(
        getMyCalendarApi(
          `?$filter=start/dateTime ge '${startDate}' and end/dateTime le '${endDate}'&$top=${pageSize}&$skip=${skip}&$expand=instances`
        ),
        {
          headers,
        }
      )
      .then(({ data }) => {
        const events = data?.value?.map(o => ({
          title: o?.subject,
          start: calenderViewDateTimeFormat(o?.start?.dateTime),
          end: calenderViewDateTimeFormat(o?.end?.dateTime),
          link: o?.onlineMeeting?.joinUrl,
        }));
        setMyCalendar(events);
        onSuccess(events);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMyChats = (
    starting = true,
    search = '',
    onSuccess = noop,
    onError = noop
  ) => {
    setChatsLoading(true);
    let filters = `?$filter=chatType eq 'oneOnOne'`;
    if (search) {
      filters += `and (members/any(a:a/email contains '${search}'))`;
    }

    let link = getMyChatsApi(filters);

    if (!starting && myChats?.next) {
      link = myChats?.next;
    }

    axios
      .get(link, {
        headers,
      })
      .then(({ data }) => {
        setMyChats(o => ({
          ...o,
          next: data['@odata.nextLink'],
          data: starting ? [...data?.value] : [...data?.value, ...o.data],
        }));
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setChatsLoading(false);
      });
  };

  const clearChats = () => {
    setMyChats(paginationState);
  };

  const getOnetoOneChatUsersDetails = (
    chatId,
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);
    axios
      .get(getChatUsersApi(chatId), {
        headers,
      })
      .then(({ data }) => {
        const userId = getUserId();
        const index = data?.value?.findIndex(o => o.userId !== userId);

        if (index > -1) {
          const user = data?.value[index];
          onSuccess(user);
        } else {
          onError(new Error('User details not found'));
        }
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createChatId = (chatId, onSuccess = noop) => {
    setChatId(chatId);
    onSuccess(chatId);
  };

  const getChatMessages = (
    starting = true,
    onSuccess = noop,
    onError = noop
  ) => {
    setMessagesLoading(true);
    const pageSize = DEFAULT_PAGE_SIZE;
    const skip = DEFAULT_PAGE_SKIP;

    let link = getChatMessagesApi(chatId);

    if (!starting && chatMessages?.next) {
      link = chatMessages?.next;
    } else {
      setChatId(chatId);
    }

    axios
      .get(link, {
        headers,
      })
      .then(({ data }) => {
        setChatMessages(o => ({
          ...o,
          next: data['@odata.nextLink'],
          data: starting
            ? [...data?.value?.reverse()]
            : [...data?.value?.reverse(), ...o.data],
        }));
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setMessagesLoading(false);
      });
  };

  const clearChatMessages = (onSuccess = noop) => {
    setChatId(null);
    setChatMessages(paginationState);
    onSuccess();
  };

  const sendMessage = (
    message = '',
    attachments = [],
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);

    const payload = {
      body: {
        content: message,
        // contentType: attachments?.length ? 'reference' : 'text',
      },
      attachments,
    };

    axios
      .post(sendMessageApi(chatId), payload, {
        headers,
      })
      .then(({ data }) => {
        setChatMessages(o => ({
          ...o,
          data: [...o.data, { ...data }],
        }));
        onSuccess();
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createChatOneOnOne = (userId, onSuccess = noop, onError = noop) => {
    setLoading(true);

    const members = [
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${myProfile?.id}')`,
      },
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${userId}')`,
      },
    ];

    const payload = {
      chatType: 'oneOnOne',
      members,
    };

    axios
      .post(createChatApi(chatId), payload, {
        headers,
      })
      .then(({ data }) => {
        setChatMessages(o => ({
          ...o,
          data: [...o.data, { ...data }],
        }));
        onSuccess();
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createGroupChat = (
    topic = '',
    users = [],
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);

    const members = [
      {
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${myProfile?.id}')`,
      },
      ...users.map(o => ({
        '@odata.type': '#microsoft.graph.aadUserConversationMember',
        roles: ['owner'],
        'user@odata.bind': `https://graph.microsoft.com/v1.0/users('${o}')`,
      })),
    ];

    const payload = {
      chatType: 'group',
      topic,
      members,
    };

    axios
      .post(createChatApi(chatId), payload, {
        headers,
      })
      .then(({ data }) => {
        setChatMessages(o => ({
          ...o,
          data: [...o.data, { ...data }],
        }));
        onSuccess();
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    chatsLoading,
    messagesLoading,
    usersLoading,
    myCalendar,
    myChats,
    chatMessages,
    myProfile,
    chatId,
    users,
    getMyCalender,
    getMyChats,
    clearChats,
    getOnetoOneChatUsersDetails,
    getChatMessages,
    clearChatMessages,
    getMyProfile,
    getUserProfile,
    getUserProfileImage,
    sendMessage,
    createChatId,
    getAllUsers,
    clearAllUsers,
    createChatOneOnOne,
    createGroupChat,
  };

  return (
    <MsTeamsContext.Provider value={contextValue}>
      {children}
    </MsTeamsContext.Provider>
  );
};
