//App
export const APP = '/app';
export const HOME = '/university';

export const COMPANY_DETAILS = '/university/company/details';
export const DASHBOARD_JOB_HUNT_STATUS =
  '/university/dashboard/job-hunting-status';
//My Students
export const MY_STUDENTS = '/university/my-students';
export const JOB_HUNTING_STATUS = `${MY_STUDENTS}/job-hunting`;
export const JOB_HUNTING_NOTES = `${JOB_HUNTING_STATUS}/notes`;
export const JOB_HUNTING_JOB_DETAILS = `${JOB_HUNTING_STATUS}/details`;
export const JOB_HUNTING_COMPANY_DETAILS = `${JOB_HUNTING_STATUS}/company`;
export const INTERNSHIP_STUDENTS_STATUS = `${MY_STUDENTS}/internship-students`;
export const INTERNSHIP_STUDENTS_NOTES = `${INTERNSHIP_STUDENTS_STATUS}/notes`;
export const INTERNSHIP_STUDENTS_INTERNSHIP_DETAILS = `${INTERNSHIP_STUDENTS_STATUS}/details`;
export const INTERNSHIP_STUDENTS_COMPANY_DETAILS = `${INTERNSHIP_STUDENTS_STATUS}/company`;

//Relationship and Handshake
export const RELATIONSHIP = `${HOME}/relationship`;
export const RELATIONSHIP_REQUEST = `${RELATIONSHIP}/request`;
export const RELATIONSHIP_REQUEST_SHARE_DOCUMENT = `${RELATIONSHIP_REQUEST}/share-documents`;
export const RELATIONSHIP_REQUEST_SHARE_DOCUMENT_UPLOAD = `${RELATIONSHIP_REQUEST_SHARE_DOCUMENT}/upload`;
export const RELATIONSHIP_HANDSHAKE = `${RELATIONSHIP}/handshake`;
export const RELATIONSHIP_HANDSHAKE_SHARE_DOCUMENT = `${RELATIONSHIP_HANDSHAKE}/share-documents`;
export const RELATIONSHIP_HANDSHAKE_SHARE_DOCUMENT_UPLOAD = `${RELATIONSHIP_HANDSHAKE_SHARE_DOCUMENT}/upload`;
export const RELATIONSHIP_DELETED = `${RELATIONSHIP}/deleted`;
export const RELATIONSHIP_COMPANY_DETAILS = `${RELATIONSHIP_REQUEST}/company`;
export const RELATIONSHIP_HANDSHAKE_COMPANY_DETAILS = `${RELATIONSHIP_HANDSHAKE}/company`;
export const RELATIONSHIP_DELETED_COMPANY_DETAILS = `${RELATIONSHIP_DELETED}/company`;

//Scholarship
export const SCHOLARSHIP = '/university/scholarship';
export const DONATION = '/university/donation';
export const SCHOLARSHIP_COMPANIES_OFFERS = `${SCHOLARSHIP}`;
export const SCHOLARSHIP_DONATION = `${SCHOLARSHIP_COMPANIES_OFFERS}?tab=2`;
export const SCHOLARSHIP_COMPANY_DETAILS = `${SCHOLARSHIP_COMPANIES_OFFERS}/company`;
export const SCHOLARSHIP_APPLICATION = `${DONATION}`;
export const SCHOLARSHIP_APPLICATION_DONATION = `${SCHOLARSHIP_APPLICATION}/?tab=2`;
export const SCHOLARSHIP_APPLICATION_INVOICE = `${SCHOLARSHIP_APPLICATION}/?tab=3`;
export const SCHOLARSHIP_APPLICATION_RECEIPT = `${SCHOLARSHIP_APPLICATION}/?tab=4`;
export const SCHOLARSHIP_APPLICATION_RECEIVED_SCHOLARSHIP = `${SCHOLARSHIP_APPLICATION}/?tab=5`;
export const SCHOLARSHIP_APPLICATION_COMPANY_DETAILS = `${SCHOLARSHIP_APPLICATION}/company`;
export const SCHOLARSHIP_VIEW_INVOICES = `${SCHOLARSHIP_APPLICATION}/view-invoices`;
export const DONATION_VIEW_INVOICES = `${DONATION}/view-invoices`;

//Jobs - Public Jobs
export const JOBS = '/university/jobs';
export const PUBLIC_JOBS = `${JOBS}/public-jobs`;
export const PUBLIC_JOBS_RECOMMENDATION = `${PUBLIC_JOBS}?tab=2`;
export const PUBLIC_JOBS_RECOMMENDATION_ACCEPTED = `${PUBLIC_JOBS}?tab=3`;
export const PUBLIC_JOBS_RECOMMENDATION_DENIED = `${PUBLIC_JOBS}?tab=4`;
export const PUBLIC_JOBS_INTERVIEW_RESULT = `${PUBLIC_JOBS}?tab=5`;
export const PUBLIC_JOBS_COMPANY_POSTS_VIEW = `${PUBLIC_JOBS}/posts`;
export const PUBLIC_JOBS_RECOMMENDATION_DETAILS = `${PUBLIC_JOBS}/recommonded-jobs`;
export const PUBLIC_JOBS_ACCEPTED_DETAILS = `${PUBLIC_JOBS}/accepted-jobs`;
export const JOB_POST_DETAILS = `${PUBLIC_JOBS}/details`;
export const PUBLIC_JOBS_COMPANY_DETILS = `${PUBLIC_JOBS}/company`;

//Jobs-Jobs for Univeersity
export const JOBS_FOR_UNIVERSITY = `${JOBS}/jobs-for-university`;
export const JOBS_FOR_UNIVERSITY_RECOMMENDATION = `${JOBS_FOR_UNIVERSITY}?tab=2`;
export const JOBS_FOR_UNIVERSITY_RECOMMENDATION_ACCEPTED = `${JOBS_FOR_UNIVERSITY}?tab=3`;
export const JOBS_FOR_UNIVERSITY_RECOMMENDATION_DENIED = `${JOBS_FOR_UNIVERSITY}?tab=4`;
export const JOBS_FOR_UNIVERSITY_POST_DETAILS = `${JOBS_FOR_UNIVERSITY}/posts`;
export const JOBS_FOR_UNIVERSITY_ACCEPTED_JOBS_STATUS = `${JOBS_FOR_UNIVERSITY}/accepted-jobs`;
export const JOBS_FOR_UNIVERSITY_RECOMMENDATION_DETAILS = `${JOBS_FOR_UNIVERSITY}/requested-jobs`;
export const JOBS_FOR_UNIVERSITY_COMPANY_DETAILS = `${JOBS_FOR_UNIVERSITY}/company`;
export const JOB_FOR_UNIVERSITY_JOB_DETAILS = `${JOBS_FOR_UNIVERSITY}/details`;

//Seminars
export const SEMINARS = `${HOME}/seminars`;
export const PUBLIC_SEMINARS = `${SEMINARS}/public`;
export const SEMINAR_DESCRIPTION = '/university/seminar/description';
export const SEMINAR_REQUESTED_VIEW = `${PUBLIC_SEMINARS}/requested-seminar-view`;
export const ACCEPTED_SEMINAR_VIEW = `${PUBLIC_SEMINARS}/accepted-seminar-view`;
export const SEMINAR_POSTED_STATUS = `${PUBLIC_SEMINARS}/posts`;
export const SEMINAR_DETAILS = `${PUBLIC_SEMINARS}/details`;
export const ATTENDED_SEMINAR_VIEW = `${PUBLIC_SEMINARS}/attended-seminar/view`;
export const ATTENDED_SEMINAR_VIEW_STUDENTS = `${PUBLIC_SEMINARS}/attended-seminar/view/students`;
export const PUBLIC_DENIED_SEMINAR = `${PUBLIC_SEMINARS}/denied-seminars`;
export const PUBLIC_SEMINARS_POSTS = `${PUBLIC_SEMINARS}/posts`;
export const publicCompanySeminars = (companyId = ':companyId') =>
  `${PUBLIC_SEMINARS}/${companyId}/posts`;

export const UNIVERSITY_SEMINARS = `${SEMINARS}/seminar-for-university`;
export const univCompanySeminars = (companyId = ':companyId') =>
  `${UNIVERSITY_SEMINARS}/${companyId}/posts`;
export const UNIVERSITY_SEMINAR_REQUESTED_VIEW = `${UNIVERSITY_SEMINARS}/requested-seminar-view`;
export const UNIVERSITY_SEMINAR_ACCEPTED_VIEW = `${UNIVERSITY_SEMINARS}/accepted-seminar-view`;
export const UNIVERSITY_SEMINAR_ATTENDED_VIEW = `${UNIVERSITY_SEMINARS}/attended-semiars`;
export const UNIVERSITY_SEMINAR_ATTENDED_STUDENTS_VIEW = `${UNIVERSITY_SEMINAR_ATTENDED_VIEW}/students-list`;
export const UNIVERSITY_SEMINAR_DETAILS = `${UNIVERSITY_SEMINARS}/details`;

//Internships - Public Internships
export const INTERNSHIPS = `${HOME}/internship`;
export const PUBLIC_INTERNSHIPS = `${INTERNSHIPS}/public-internship`;
export const publicPostedInternships = (companyId = ':id') =>
  `${INTERNSHIPS}/public-internship/${companyId}/posted`;
export const PUBLIC_INTERNSHIPS_DENIED = `${PUBLIC_INTERNSHIPS}?tab=3`;
export const PUBLIC_INTERNSHIPS_ACCEPTED = `${PUBLIC_INTERNSHIPS}?tab=2`;
export const PUBLIC_INTERNSHIPS_APPLICATION_DETAILS = `${PUBLIC_INTERNSHIPS}/accepted-application-details`;
export const PUBLIC_INTERNSHIPS_COMPANY_DETAILS = `${PUBLIC_INTERNSHIPS}/company`;
export const PUBLIC_INTERNSHIPS_ACCEPTED_STATUS = `${PUBLIC_INTERNSHIPS}/accepted-internships`;
export const PUBLIC_INTERNSHIPS_REQUESTED_INTERNSHIPS = `${PUBLIC_INTERNSHIPS}/requested-internships`;
export const PUBLIC_INTERNSHIPS_DETAILS = `${PUBLIC_INTERNSHIPS}/details`;
export const PUBLIC_INTERNSHIPS_DENIED_STUDENT_LIST = `${PUBLIC_INTERNSHIPS}/denied-student-list`;

//Internships - University Internships
export const UNIVERSITY_INTERNSHIP = `${INTERNSHIPS}/internship-for-university`;
export const UNIVERSITY_INTERNSHIP_POSTED_BY_COMPANY = `${UNIVERSITY_INTERNSHIP}/posts`;
export const univPostedInternships = (companyId = ':id') =>
  `${UNIVERSITY_INTERNSHIP}/${companyId}/posts`;
export const UNIVERSITY_INTERNSHIP_RECOMMENDATION = `${UNIVERSITY_INTERNSHIP}?tab=2`;
export const UNIVERSITY_INTERNSHIP_RECOMMENDATION_BY_COMPANY = `${UNIVERSITY_INTERNSHIP}/recommondations`;
export const UNIVERSITY_INTERNSHIP_ACCEPTED = `${UNIVERSITY_INTERNSHIP}?tab=3`;
export const UNIVERSITY_INTERNSHIP_DENIED = `${UNIVERSITY_INTERNSHIP}?tab=4`;
export const UNIVERSITY_INTERNSHIP_INTERVIEW_REQUEST = `${UNIVERSITY_INTERNSHIP}?tab=5`;
export const UNIVERSITY_INTERNSHIP_UPDATE_RESULT = `${UNIVERSITY_INTERNSHIP}?tab=6`;
export const UNIVERSITY_INTERNSHIP_POST = `${UNIVERSITY_INTERNSHIP}/posts`;
export const UNIVERSITY_INTERNSHIP_INTERNAL_INTERVIEW_REQUEST_DETAILS = `${UNIVERSITY_INTERNSHIP}/internal-interview-request`;
export const UNIVERSITY_INTERNSHIP_POST_DETAILS = `${UNIVERSITY_INTERNSHIP}/post/details`;
export const UNIVERSITY_INTERNSHIP_UPDATE_RESULT_DETAILS = `${UNIVERSITY_INTERNSHIP}/update-result`;
export const UNIVERSITY_INTERNSHIP_COMPANY_DETAILS = `${UNIVERSITY_INTERNSHIP}/company`;
export const UNIVERSITY_INTERNSHIPS_DETAILS = `${UNIVERSITY_INTERNSHIP}/details`;
export const UNIVERSITY_INTERNSHIPS_ACCEPTED_STATUS = `${UNIVERSITY_INTERNSHIP}/accepted-recommondations`;

//Internship - Interview
export const INTERNSHIP_INTERVIEW = `${INTERNSHIPS}/interview`;
export const INTERNSHIP_INTERVIEW_RESULT_UPDATE = `${INTERNSHIP_INTERVIEW}?tab=2`;
export const INTERNSHIP_INTERVIEW_REQUEST_DETAILS = `${INTERNSHIP_INTERVIEW}/request`;
export const INTERNSHIP_INTERVIEW_UPDATE_RESULT_DETAILS = `${INTERNSHIP_INTERVIEW}/update-result`;
export const INTERNSHIP_INTERVIEW_COMPANY_DETAILS = `${INTERNSHIP_INTERVIEW}/company`;

//Internsip - Agreement
export const UNIVERSITY_CONTRACT = `${HOME}/contract`;
export const INTERNSHIP_AGREEMENT = `${INTERNSHIPS}/agreement`;
export const INTERNSHIP_AGREEMENT_ACCEPTED = `${INTERNSHIP_AGREEMENT}?tab=2`;
export const INTERNSHIP_AGREEMENT_HOLD = `${INTERNSHIP_AGREEMENT}?tab=3`;
export const INTERNSHIP_AGREEMENT_DENIED = `${INTERNSHIP_AGREEMENT}?tab=4`;
export const INTERNSHIP_AGREEMENT_SEND_DETAILS = `${INTERNSHIP_AGREEMENT}/send`;
export const INTERNSHIP_AGREEMENT_UPLOAD = `${INTERNSHIP_AGREEMENT}/upload`;

//Dashboard
export const DASHBOARD = '/university/dashboard';
export const DASHBOARD_APPLICATION_STATUS =
  '/university/questionnaires-results';
export const DASHBOARD_QUESTIONNAIRE = '/university/send-questionnaire';
export const PROFILE_VIEWED = '/university/dashboard/companies/viewed-profile';
export const COMPANY_PROFILE = '/university/dashboard/companies';

//Notification
export const NOTIFICATION = '/university/notification';
export const NOTIFICATION_SEMINAR = '/university/notification?tab=1';
export const NOTIFICATION_INTERNSHIP = '/university/notification?tab=2';

//Profile
export const PROFILE = '/university/profile';
export const EDIT_PROFILE = '/university/profile/edit';
export const STUDENT_RESUME = '/university/profile/resume';

// Help and Feedback
export const SETTINGS = '/university/settings';

//Setting
export const HELP_AND_FEEDBACK = '/university/help-and-feedback';
export const HELP_CREATE_ACCOUNT = `${HELP_AND_FEEDBACK}/create-an-account`;

//Auth
export const LOGIN = '/app/login';
export const REGISTER = '/app/register';
export const CREATE_PROFILE = '/app/create-profile';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';
export const CREATE_PASSWORD = '/app/create-password';
export const SIGNOUT = '/app/signout';
export const LOGIN_CALLBACK = '/login/callback';
export const APP_SELECT = '/select-app';

//Error
export const ERROR_PAGE = '/app/error-page';

//Admin
export const ADMIN = `${HOME}/admin`;
export const MANAGE_USERS = `${ADMIN}/manage-users`;
export const MANAGE_SUBSCRIPTIONS = `${ADMIN}/manage-subscriptions`;
export const MANAGE_SUBSCRIPTIONS_PAYMENT = `${MANAGE_SUBSCRIPTIONS}/payment`;
export const ADMIN_PROFILE = `${ADMIN}/profile`;
export const ADMIN_PROFILE_EDIT = `${ADMIN_PROFILE}/edit`;

export const DEFAULT_COMPANY_DETAILS = `${HOME}/company`;

//Career Office
export const CAREER_OFFICE = `${HOME}/career-office`;
export const CAREER_OFFICE_MY_PROFILE = `${CAREER_OFFICE}/my-profile`;
export const CHAT = `${CAREER_OFFICE}/chat`;
export const STUDENT_CHAT = `${CHAT}/student`;
export const COMPANY_CHAT = `${CHAT}/company`;
export const TEAMS_APP = `${CHAT}/teams-app`;
export const CALENDAR = `${CAREER_OFFICE}/calendar`;

//University Profile
export const SPONSORS = `${HOME}/sponsors`;

//Company Related Routes
export const UNIVERSITY_SEMINAR_COMPANY_DETAILS = `${UNIVERSITY_SEMINARS}/company`;
export const INTERNSHIP_AGREEMENT_COMPANY_DETAILS = `${INTERNSHIP_AGREEMENT}/company`;
export const SEMINAR_COMPANY_DETAILS = `${PUBLIC_SEMINARS}/company`;
export const SPONSORS_COMPANY_DETAILS = `${SPONSORS}/company`;

export const publicViewCompaniesJobsList = (companyId = ':companyId') =>
  `${PUBLIC_JOBS}/posts/${companyId}`;
export const publicViewRecommendationJobsList = (companyId = ':companyId') =>
  `${PUBLIC_JOBS}/recommonded-jobs/${companyId}`;
export const publicViewAcceptedJobsList = (companyId = ':companyId') =>
  `${PUBLIC_JOBS}/accepted-jobs/${companyId}`;

  export const publicViewRecommendationJobsStudentList = (companyId = ':companyId', jobId = ":jobId") =>
  `${PUBLIC_JOBS}/recommonded-jobs/${companyId}/jobs/${jobId}/students`;
