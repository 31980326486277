import { LS_ACCESS_TOKEN } from 'utils/constants/localStorage';

export const decodeJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getUserDetails = () => {
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const data = decodeJwt(accessToken);

  if (data)
    return {
      userId: data?.oid,
      name: data?.name,
    };
  else return null;
};

export const getUserId = () => {
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const data = decodeJwt(accessToken);

  if (data) return data?.oid;
  else return null;
};

export const getUserName = () => {
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const data = decodeJwt(accessToken);

  if (data) return data?.name;
  else return null;
};
