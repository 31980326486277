import dashboard from './dashboard.json';
import settings from './settings.json';
import notification from './notification.json';
import favorites from './favorites.json';
import common from './common.json';
import seminar from './seminar.json';
import job from './job.json';
import login from './login.json';
import myStudents from './myStudents.json';
import relationship from './relationship.json';
import helpAndFeedback from './helpAndFeedback.json';
import internships from './internships.json';
import scholarhip from './scholarhip.json';
import chat from './chat.json';
import companyDetails from './companyDetails.json';
import jobDetails from './jobDetails.json';
import seminarDetails from './seminarDetails.json';
import internshipDetails from './internshipDetails.json';

const pages = {
  ...settings,
  ...notification,
  ...favorites,
  ...common,
  ...seminar,
  ...job,
  ...dashboard,
  ...login,
  ...myStudents,
  ...relationship,
  ...helpAndFeedback,
  ...internships,
  ...scholarhip,
  ...chat,
  ...companyDetails,
  ...jobDetails,
  ...seminarDetails,
  ...internshipDetails,
};

export default pages;
