import { useSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  getStudentProfileApi,
  getUserSettingsApi,
  loginSSOUserApi,
  loginUserApi,
  logoutSSOUserApi,
  resetPasswordApi,
  signupUserApi,
  updateUserSettingsApi,
} from 'utils/constants/apis';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_LANGUAGE } from 'utils/constants/app';
import {
  LS_ACCESS_TOKEN,
  LS_ADMIN_USER,
  LS_UNIVERSITY_NAME,
  LS_USER_ID,
  LS_USER_NAME,
  LS_USER_PROFILE_DATA,
  setLocalStorageItem,
} from 'utils/constants/localStorage';
import { DEFAULT_TIMEZONE } from 'utils/constants/settings';
import axiosInstance from 'utils/helpers/axios';

const UserContext = createContext({
  loading: false,
  user: null,
  auth: null,
  getUserProfile: noop,
  handleLogin: noop,
  clearSession: noop,
  getSettings: noop,
  updateSettings: noop,
  resetPassword: noop,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const defaultAuth = localStorage.getItem(LS_USER_ID) || null;
  const [auth, setAuth] = useState(defaultAuth);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('app');

  const getUserProfile = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .get(getStudentProfileApi())
      .then(data => {
        const userProfileData = {
          userId: data?.id,
          userName: data?.name,
          universityName: data?.university?.name,
          isAdminUser: data?.isAdmin,
          // isAdminUser: true,
          universityId: data?.university?.id,
          universityImg: data?.university?.profilePicture,
        };
        setLocalStorageItem(LS_USER_ID, data?.id);
        setLocalStorageItem(LS_USER_PROFILE_DATA, userProfileData);

        // Set the user data
        setUser(data);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });

        // Call the onError callback
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const handleLogin = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(loginUserApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Login successful'), {
          variant: 'success',
        });
        const { id } = data;
        localStorage.setItem(LS_USER_ID, String(id));
        setAuth(id);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        // localStorage.removeItem(LS_USER_ID);
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const handleLoginSSO = (onSuccess = noop, onError = noop) => {
    window.location.href = loginSSOUserApi();
  };

  const handleSignup = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(signupUserApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Registered Successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const handleLogoutSSO = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(logoutSSOUserApi())
      .then(data => {
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const clearSession = () => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_ACCESS_TOKEN);
    localStorage.clear();
    setAuth(null);
  };

  const getSettings = (onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .put(getUserSettingsApi(`/${auth}`))
      .then(data => {
        const { timezone, language } = data;
        setTimezone(timezone);
        setLanguage(language);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });

      })
      .finally(() => setLoading(false));
  };

  const updateSettings = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(updateUserSettingsApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Updated settings successfully'), {
          variant: 'success',
        });
        const { timezone, language } = data;
        setTimezone(timezone);
        setLanguage(language);
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const resetPassword = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(resetPasswordApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Password reset successful'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => setLoading(false));
  };

  const contextValue = {
    loading,
    auth,
    user,
    timezone,
    language,
    handleLogin,
    getUserProfile,
    handleSignup,
    clearSession,
    getSettings,
    updateSettings,
    resetPassword,
    handleLoginSSO,
    handleLogoutSSO,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
